import React from 'react';
import { IDocumento } from '../../../../../../interfaces/IDocumento';
import { IPretendenteValidacaoErroCampos } from '../../../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../../../CampoDeTextoComMascara';
import ICnh from '../../../../../../interfaces/ICnh';
import CampoDeTexto from '../../../../../CampoDeTexto';

interface IInputEventsProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICampoCnhProps {
    documentos: IDocumento,
    erros?: IPretendenteValidacaoErroCampos,
    inputEvents: IInputEventsProps,
    disabled: boolean,
    onChangeDocumento: (documentos: IDocumento) => void
}

const CampoCnh = ({ documentos, erros, inputEvents, disabled, onChangeDocumento }: ICampoCnhProps) => {

  const handleOnChangeDocumento = (documentos: IDocumento, event: React.ChangeEvent<HTMLInputElement>, nomePropriedade: string) => {
    if (documentos.cnh) {
      const cnh: ICnh = documentos.cnh;
      onChangeDocumento({ ...documentos, cnh: { ...cnh, [nomePropriedade]: event.target.value } });
    }
  };

  return (<div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTexto
        label="Número"
        name="numeroCnh"
        maxLength={20}
        value={documentos.cnh?.numero || ''}
        tipo="text"
        erro={erros?.numeroCnh}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'numero'); }} />
      <CampoDeTexto
        label="Categoria"
        name="categoriaCnh"
        maxLength={5}
        value={documentos.cnh?.categoria || ''}
        tipo="text"
        erro={erros?.categoriaCnh}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'categoria'); }} />
    </div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTextoComMascara
        label="Data de emissão"
        name="dataDeEmissaoCnh"
        mascara="99/99/9999"
        value={documentos.cnh?.dataDeEmissao?.toString() || ''}
        tipo="text"
        erro={erros?.dataDeEmissaoCnh}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'dataDeEmissao'); }} />
      <CampoDeTextoComMascara
        label="Data de validade"
        name="dataDeValidadeCnh"
        mascara="99/99/9999"
        value={documentos.cnh?.dataDeValidade?.toString() || ''}
        tipo="text"
        erro={erros?.dataDeValidadeCnh}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'dataDeValidade'); }} />
    </div>
  </div>);
};

export default CampoCnh;