/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Header from '@bit/digix.digixui.header';
import { ReactComponent  as logoHabix } from '../Logos/logo-habix.svg';
import MenuDeTrocaDeModulos from '../Header/componentes/MenuDeTrocaDeModulos';
import { limparLocalDeAtendimento } from '../../actions/informacoesDeLocalDeAtendimento.actions';
import ModalDeAlteracaoDeSenha from '../ModalDeAlteracaoDeSenha';
import { efetuarLogOut } from '../../actions/login.actions';

const urlPoliticaDePrivacidade = process.env.REACT_APP_URL_POLITICA_DE_PRIVACIDADE;
const urlTermoDeUso = process.env.REACT_APP_URL_TERMO_DE_USO;

function obterLoginDoUsuario(usuarioLogado) {
  let existeUsuarioLogado = usuarioLogado !== undefined && Object.keys(usuarioLogado).length > 0;
  let login = existeUsuarioLogado ? usuarioLogado?.login?.toLowerCase() : '';
  return login;
}

const informacoesDeLocalDeAtendimentoUsuario = (localAtendimentoRedux) => {
  if (Object.keys(localAtendimentoRedux.localDeAtendimento).length === 0) {
    const localAtendimentoStorage = localStorage.getItem('localDeAtendimento');
    const podeAlterarLocalDeAtendimentoStorage = localStorage.getItem('podeAlterarLocalDeAtendimento');
    if (localAtendimentoStorage != null) {
      localAtendimentoRedux.localDeAtendimento = JSON.parse(localAtendimentoStorage);
      localAtendimentoRedux.podeAlterarLocalDeAtendimento = podeAlterarLocalDeAtendimentoStorage === 'true';
    }
  }
  return localAtendimentoRedux;
};

const CabecalhoAdministrativo = (props) => {
  const [exibirDialogo, alterarExibicaoDoDialogo] = useState(false);
  
  const dispatch = useDispatch();
  let localAtendimentoRedux = useSelector(state => state.informacoesDeLocalDeAtendimento);
  let usuarioLogado = useSelector(state => state.usuario);
  const informacoesDeLocalDeAtendimento = informacoesDeLocalDeAtendimentoUsuario(localAtendimentoRedux);
  let localDeAtendimento = informacoesDeLocalDeAtendimento.localDeAtendimento;
  let podeAlterarLocalDeAtendimento = informacoesDeLocalDeAtendimento.podeAlterarLocalDeAtendimento;
  let possuiLocalDeAtendimento = localDeAtendimento !== undefined && Object.keys(localDeAtendimento).length > 0;
  
  let login = obterLoginDoUsuario(usuarioLogado);

  const sairDoSistema = (evento) => {
    evento.preventDefault();
    dispatch(efetuarLogOut());
  };

  const alterarLocalDeAtendimento = (evento) => {
    evento.preventDefault();
    if (!podeAlterarLocalDeAtendimento) return;

    dispatch(limparLocalDeAtendimento());
    localStorage.removeItem('localDeAtendimento');
    dispatch(push('locais-de-atendimento'));
  };

  const abrirModal = () => {
    alterarExibicaoDoDialogo(true);
  };

  const fecharModal = () => {
    alterarExibicaoDoDialogo(false);
  };

  let botoesDeAcoesDoUsuario = [
    (
      <a href="#alterarSenha" onClick={abrirModal} className="dropdown__link">
        <i className="fal fa-exchange dropdown__icone"></i>
        Alterar Senha
      </a>
    ),
    (
      <a href={urlTermoDeUso} target="_blank" className="dropdown__link" rel="noreferrer">
        <i className="fal fa-file-check dropdown__icone"></i>
        Termo de Uso
      </a>
    ),
    (
      <a href={urlPoliticaDePrivacidade} target="_blank" className="dropdown__link" rel="noreferrer">
        <i className="fal fa-lock dropdown__icone"></i>
        Política de Privacidade
      </a>
    ),
    (
      <a href="#sair" onClick={(evento) => sairDoSistema(evento)} className="dropdown__link">
        <i className="far fa-sign-out dropdown__icone"></i>
        Sair
      </a>
    )
  ];

  let itensPrimarios =
    <>
      <MenuDeTrocaDeModulos />
    </>;

  let itensSecundarios =
    <>
      <li className="dropdown dropdown_direita">
        <a className="cabecalho-sistema__item-de-menu" aria-haspopup="true" href="#">
          Suporte <i className="far fa-angle-down u-margem-esquerda-pequena"></i>
        </a>
      </li>

      {possuiLocalDeAtendimento &&
        <li>
          <a className="cabecalho-sistema__item-de-menu" href="#" onClick={(evento) => alterarLocalDeAtendimento(evento)}>
            {localDeAtendimento.descricao} - {localDeAtendimento.cidadeComUf}
            {podeAlterarLocalDeAtendimento &&
              <i className="far fa-exchange u-margem-esquerda-pequena"></i>
            }
          </a>
        </li>
      }
    </>;

  return (
    <div className="tema-habix">
      <Header
        component={logoHabix}
        nomeDoUsuario={login}
        comMenuLateral={true}
        botoesDeAcoesDoUsuario={botoesDeAcoesDoUsuario}
        itensPrimarios={itensPrimarios}
        itensSecundarios={itensSecundarios}
      >
      </Header>
      <ModalDeAlteracaoDeSenha deveExibirDialogo={exibirDialogo} fecharDialogo={fecharModal} />
      {props.children}
    </div>
  );
};

export default CabecalhoAdministrativo;