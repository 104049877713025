import React from 'react';
import { Link } from 'react-router-dom';
import IImovelProprio from '../../../../interfaces/IImovelProprio';

interface ItemImovelProps {
  imovel: IImovelProprio,
  removerImovelDaListagem: (idDoImovel: number) => void,
  disabled: boolean
}

const ItemImovel = ({ imovel, removerImovelDaListagem, disabled }: ItemImovelProps) => {

  const { id, endereco, estaQuitado, tipo } = imovel;

  return (
    <div className="lista-de-cadastro__item">
      <div className='grade'>

        <div className='grade__linha grade__linha_alinhar-vertical-centro grade__linha_distribuir-horizontal-entre-elementos'>
          <div className='grade_coluna grade_coluna_10'>

            <div className="lista-de-cadastro__info">
              <div className="lista-de-cadastro__icone">
                <i className="fal fa-home" aria-hidden="true"></i>
              </div>
              <div className="lista-de-cadastro__dados">
                <p data-test-id="estadoImovel" className="lista-de-cadastro__titulo">{endereco?.estado?.nome ? endereco.estado.nome : ''}</p>
                <p data-test-id="cidadeImovel" className="lista-de-cadastro__subtitulo">{endereco.cidade ? endereco.cidade.nome : ''}</p>
                {endereco.logradouro
                  ? <p data-test-id="enderecoImovel" className="lista-de-cadastro__subtitulo">{endereco.logradouro}, {endereco.numero} - {endereco.bairro}</p>
                  : ''
                }
              </div>
            </div>

            <div className="lista-de-cadastro__tags">
              {(estaQuitado) &&
                <p data-test-id="quitado" className="lista-de-cadastro__tag">Quitado</p>}
              <p data-test-id="tipoDeImovel" className="lista-de-cadastro__tag">{tipo == 'CasaOuApartamento' ? 'Casa / Apartamento' : tipo}</p>
            </div>
          </div>
          <div className="grade_coluna grade_coluna_2">
            <div className="lista-de-cadastro__acoes">
              {!disabled && <>
                <button
                  data-test-id="botaoExcluirImovel"
                  className="botao botao_pequeno botao_largura-total botao_contorno botao_com-icone-para-esquerda botao_erro"
                  onClick={() => removerImovelDaListagem(id as number)}>
                  <i className="fal fa-trash-alt" aria-hidden="true"></i>Excluir
                </button>
                <Link
                  to={`/editar-imovel/${id}`}
                  data-test-id="botaoEditarImovel"
                  className="botao botao_pequeno botao_largura-total botao_contorno botao_com-icone-para-esquerda">
                  <i className="fal fa-edit" aria-hidden="true"></i>Editar
                </Link>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemImovel;
